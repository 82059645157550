// import React, { useState, useEffect } from 'react';
// import { ListGroup } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

// import navigation from '../../../menu-items';
// import { BASE_TITLE, BASENAME } from '../../../config/constant';

// const Breadcrumb = () => {
//   const [main, setMain] = useState([]);
//   const [item, setItem] = useState([]);

//   useEffect(() => {
//     navigation.items.map((item, index) => {
//       if (item.type && item.type === 'group') {
//         getCollapse(item, index);
//       }
//       return false;
//     });
//   });

//   const getCollapse = (item) => {
//     if (item.children) {
//       item.children.filter((collapse) => {
//         if (collapse.type && collapse.type === 'collapse') {
//           getCollapse(collapse);
//         } else if (collapse.type && collapse.type === 'item') {
//           if (document.location.pathname === BASENAME + collapse.url) {
//             setMain(item);
//             setItem(collapse);
//           }
//         }
//         return false;
//       });
//     }
//   };

//   let mainContent, itemContent;
//   let breadcrumbContent = '';
//   let title = '';

//   if (main && main.type === 'collapse') {
//     mainContent = (
//       <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
//         <Link to="#">{main.title}</Link>
//       </ListGroup.Item>
//     );
//   }

//   if (item && item.type === 'item') {
//     title = item.title;
//     itemContent = (
//       <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
//         <Link to="#">{title}</Link>
//       </ListGroup.Item>
//     );

//     if (item.breadcrumbs !== false) {
//       breadcrumbContent = (
//         <div className="page-header">
//           <div className="page-block">
//             <div className="row align-items-center">
//               <div className="col-md-12">
//                 <div className="page-header-title">
//                   <h5 className="m-b-10">{title}</h5>
//                 </div>
//                 <ListGroup as="ul" bsPrefix=" " className="breadcrumb">
//                   <ListGroup.Item as="li" bsPrefix=" " className="breadcrumb-item">
//                     <Link to="/">
//                       <i className="feather icon-home" />
//                     </Link>
//                   </ListGroup.Item>
//                   {mainContent}
//                   {itemContent}
//                 </ListGroup>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     }

//     document.title = title + BASE_TITLE;
//   }

//   return <React.Fragment>{breadcrumbContent}</React.Fragment>;
// };

// export default Breadcrumb;

import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Link, { LinkProps } from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { withRouter } from 'react-router-dom';
import { Card } from 'react-bootstrap';

const Breadcrumb = (props) => {
  const {
    history,
    location: { pathname }
  } = props;
  const pathnames = pathname.split('/').filter((x) => x);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (pathname !== '/signin' && pathname !== '/change-password' && pathname !== '/') {
      // if (pathname !== '/signin' && pathname !== '/change-password' && pathname !== '/') {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [pathname]);

  return (
    <Card className={show ? 'd-block m-0 ' : 'd-none'} style={{ backgroundColor: 'transparent' }}>
      <Card.Header className="p-2">
        <Breadcrumbs className="d-flex justify-content-end" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {pathnames.length > 0 ? (
            <Link style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
              Home
            </Link>
          ) : (
            <Typography> Home </Typography>
          )}
          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            return isLast ? (
              <Typography key={name}>{name}</Typography>
            ) : (
              <Link style={{ cursor: 'pointer' }} key={name} onClick={() => history.push(routeTo)}>
                {/* <i>{name.toUpperCase()}</i> */}
                {name}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Card.Header>
    </Card>
  );
  // }
};

export default withRouter(Breadcrumb);
