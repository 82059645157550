import axios from 'axios';

import { BASE_URL } from './URL';
export default axios.create({
  baseURL: BASE_URL,
  // timeout: 4000,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json'
  }
});
