// export const BASE_URL = 'https://dev-script.riofin.in/public/index.php/crm/';
// export const BASE_URL = 'http://crm.theogfoods.com/public/crm/';
export const BASE_URL = 'https://app-script.riofinassets.com/public/crm/';
// export const BASE_URL = 'http://192.168.1.33/riofin_scriptlaravel-new/public/crm/';
// export const BASE_URL = 'http://192.168.1.30/riofin/public/crm';

export const URL = {
  //DASHBOARD LOGIN

  DASHBOARD: 'user-count',
  DASHBOARD_SETTING: 'settings',
  DASHBOARD_VALUE_UPDATE: 'setting-value/update',
  DASHBOARD_GRAPH: 'investment-graph',

  // LOGIN URLs
  ADMIN_LOGIN: 'admin/login',
  ADMIN_LOGOUT: 'admin/logout',
  ADMIN_PASSWORD: 'admin/change-password',

  //  ADMIN URL
  ADMIN_LIST: 'admin',
  ADMIN_EDIT: 'admin/',

  // ROLE URL
  ROLE_LIST: 'role',
  ROLE_EDIT: 'role/',
  ROLES: 'modules',

  //INVESTMENT

  INVESTMENT_LIST: 'investment',
  INVESTMENT_EDIT: 'investment/',

  // INVESTORS

  INVESTORS_LIST: 'investors',
  INVESTORS_INVOICE_DOWNLOAD: 'invoice-download/',
  ADMIN_DOC_UPLOAD: 'upload-admin-doc/',
  OVERALL_EXCEL_DOWNLOAD: 'export',
  OVERALL_EXCEL_UPLOAD: 'import',
  UPDATED_EXCEL_DOWNLOAD: 'export?',

  //DEPARTMENT

  DEPARTMENT_LIST: 'ticket-department',
  DEPARTMENT_EDIT: 'ticket-department/',

  //BANNER URL

  BANNER_LIST: 'banner',
  BANNER_EDIT: 'banner/',

  //FAQ URL
  FAQ_LIST: 'faq',
  FAQ_EDIT: 'faq/',

  //BLOG URL

  BLOG_LIST: 'blog',
  BLOG_EDIT: 'blog/',

  //TESTIMONAL URL
  TESTIMONAL_LIST: 'testimonial',
  TESTIMONAL_EDIT: 'testimonial/',

  //PARTNER URL
  PARTNER_LIST: 'partner',
  PARTNER_EDIT: 'partner/',

  //PROJECT
  PROJECT_LIST: 'project',
  PROJECT_EDIT: 'project/',
  PROJECT_DOC: 'project-document/',
  PROJECT_FAQ: 'project-faq/',
  UPDATED_PROJECT_INVESTOR_EXCEL_DOWNLOAD: 'export-project-investment/',
  PROJECT_CLOSE: 'real-estate/',

  //REFERRALS

  REFERRALS_LIST: 'referals',
  REFERRALS_EDIT: 'referal',
  REFERRALS_PAYMENT: 'paid-amount/',

  //MANUAL PAYMENT

  PAYMENT_LIST: 'manual-payments',
  PAYMENT_EDIT: 'payment-status',
  PAYMENT_DELETE: 'manual-payments-delete',

  //USER

  USER_LIST: 'users',
  USER_EDIT: 'users/',

  //TICKETS

  TICKETS: 'ticket',
  TICKET_STATUS: 'ticket-status/',

  // REPORTS

  REPORTS: 'report',
  REPORTS_IND_EXCEL: 'export-investment',

  //RAISE CAPITAL

  RAISE_CAPITAL: 'raise-capital',
  RAISE_CAPITAL_REPLY: 'raise-capital/reply',

  //NOTIFICATION
  NOTIFICATION: 'notification-send',
  NOTIFICATION_LIST: 'notifications',
  NOTIFICATION_CURRENT: 'current-notifications',
  NOTIFICATION_STATUS: 'read-status-notification',
  NOTIFICATION_ADMIN_VIEW: 'admin-viewed-notifications'
};
