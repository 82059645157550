import httpClient from '../Constant/httpClient';
import { URL } from '../Constant/URL';

const adminLogin = (data) => {
  return httpClient.post(URL.ADMIN_LOGIN, data);
};

const adminLogout = () => {
  return httpClient.post(URL.ADMIN_LOGOUT);
};

const password_change = (data) => {
  return httpClient.post(URL.ADMIN_PASSWORD, data);
};

const loginServices = {
  adminLogin,
  password_change,
  adminLogout
};

export default loginServices;
