import React from 'react';
import { Redirect } from 'react-router-dom';

import useAuth from '../../hooks/useAuth';
import useToken from '../../services/Storage/useToken';

const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const { token } = useToken();

  if (token) {
    return <Redirect to="/" />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default GuestGuard;
