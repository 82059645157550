import React, { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import { FirebaseProvider } from './contexts/FirebaseContext';
//import { JWTProvider } from "./contexts/JWTContext";
//import { Auth0Provider } from "./contexts/Auth0Context";

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import useToken from './services/Storage/useToken';
import httpClient from './services/Constant/httpClient';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';

const App = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState(false);

  if (token) {
    httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}` || '';
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // spinning start to show
        setLoading(true);
        return config;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // spinning hide
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        return response;
      },
      function (error) {
        setLoading(false);
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <LoadingOverlay
        active={loading}
        // spinner={<BounceLoader />}
        spinner={loading}
        // text="Loading your content..."
      >
        <Router basename={BASENAME}>
          <FirebaseProvider>{renderRoutes(routes)}</FirebaseProvider>
        </Router>
      </LoadingOverlay>
    </React.Fragment>
  );
};

export default App;
