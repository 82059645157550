import React, { useContext, useEffect, useState } from 'react';
import { ListGroup, Dropdown, Media, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from './ChatList';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import useAuth from '../../../../hooks/useAuth';

import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import loginServices from '../../../../services/Service/loginservice';
import notificationServices from '../../../../services/Service/notification';
import { toast } from 'react-hot-toast';
// import { Button } from 'jodit/types/core/ui';

const NavRight = () => {
  const img = JSON.parse(localStorage.getItem('ROLE'));
  // console.log('img', img);
  const configContext = useContext(ConfigContext);
  const { logout } = useAuth();
  const { rtlLayout } = configContext.state;

  const [listOpen, setListOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [type, setType] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [notify, setNotifiy] = useState();
  const [notiStatus, setNotiStatus] = useState();
  const [notifyStatus, setNotifiyStatus] = useState();

  const setDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const clearSessions = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const handleLogout = async () => {
    try {
      loginServices.adminLogout().then((res) => {
        toast.success(res.data.message);

        setTimeout(() => {
          clearSessions();
        }, 1000);
      });
      //handleClose();
      // await logout();
    } catch (err) {
      console.error(err);
    }
  };

  const notification = () => {
    notificationServices.notificationCurrentList().then((res) => {
      // console.log('res', res);
      setNotifiy(res?.data);
      res?.data?.notifications.map((i) => {
        // console.log('tem', i.read_status);
        setNotiStatus(i.read_status);
      });
    });
  };

  // console.log(notiStatus);

  const notificationStatus = () => {
    notificationServices.notificationStatus().then((res) => {
      setNotifiyStatus(res.data);
    });
  };

  useEffect(() => {
    notification();
  }, []);

  return (
    <React.Fragment>
      <ListGroup as="ul" bsPrefix=" " className="navbar-nav ml-auto">
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} open={showDropdown} onToggle={setDropdown}>
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="feather icon-bell icon" />
              {notiStatus === 1 ? (
                <span className="badge badge-pill badge-danger">
                  <span />
                </span>
              ) : (
                ''
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="notification notification-scroll">
              <div className="noti-head">
                <h6 className="d-inline-block m-b-0">Notifications</h6>
                {/* <div className="float-right">
                  <Link to="#" className="m-r-10">
                    mark as read
                  </Link>
                  <Link to="#">clear all</Link>
                </div> */}
              </div>
              <PerfectScrollbar>
                <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                  <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">NEW</p>
                  </ListGroup.Item>
                  {notify?.notifications?.map((list) => (
                    <ListGroup.Item as="li" bsPrefix=" " className="notification">
                      {/* <PerfectScrollbar> */}
                      <Media>
                        {/* <img className="img-radius" src={avatar1} alt="Generic placeholder" /> */}
                        <Media.Body>
                          <p>
                            <b>{list?.user_id}</b>
                            {/* <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span> */}
                          </p>
                          <p>{list?.message}</p>
                        </Media.Body>
                      </Media>

                      {/* </PerfectScrollbar> */}
                    </ListGroup.Item>
                  ))}
                  {/* <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                    <p className="m-b-0">EARLIER</p>
                  </ListGroup.Item>
                  <ListGroup.Item as="li" bsPrefix=" " className="notification">
                    <Media>
                      <img className="img-radius" src={avatar2} alt="Generic placeholder" />
                      <Media.Body>
                        <p>
                          <strong>Joseph William</strong>
                          <span className="n-time text-muted">
                            <i className="icon feather icon-clock m-r-10" />
                            30 min
                          </span>
                        </p>
                        <p>Purchase New Theme and make payment</p>
                      </Media.Body>
                    </Media>
                  </ListGroup.Item> */}
                </ListGroup>
              </PerfectScrollbar>
              <div className="noti-footer">
                <Dropdown.Item href="#/push-notification">
                  <p onClick={notificationStatus}>show All</p>{' '}
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
        {/* <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown>
            <Dropdown.Toggle as={Link} variant="link" to="#" className="displayChatbox" onClick={() => setListOpen(true)}>
              <i className="icon feather icon-mail" />
              <span className="badge bg-success">
                <span />
              </span>
            </Dropdown.Toggle>
          </Dropdown>
        </ListGroup.Item> */}
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <img src={img.img_url} className="img-radius wid-40" height={35} alt="User Profile" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img src={img.img_url} className="img-radius" alt="User Profile" />
                <span>{img.user_name}</span>
                <Link onClick={handleLogout} to="#" className="dud-logout" title="Logout">
                  <i className="feather icon-log-out" />
                </Link>
              </div>
              <ListGroup as="ul" bsPrefix=" " variant="dark" className="pro-body">
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-settings" /> Settings
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-user" /> Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item">
                    <i className="feather icon-mail" /> My Messages
                  </Link>
                </ListGroup.Item> */}
                <ListGroup.Item
                  style={{ background: hover && type == 'password' ? '#000' : '', color: hover && type == 'password' ? '#fff' : '' }}
                  onMouseLeave={() => {
                    setType('');
                    setHover(false);
                  }}
                  onMouseEnter={() => {
                    setType('password');

                    setHover(true);
                  }}
                  as="li"
                  bsPrefix=" "
                >
                  <Link to="/change-password" className="dropdown-item">
                    <i className="feather icon-lock" /> Change Password
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item
                  style={{ background: hover && type == 'change' ? '#000' : '', color: hover && type == 'change' ? '#fff' : '' }}
                  onMouseLeave={() => {
                    setHover(false);
                    setType('');
                  }}
                  onMouseEnter={() => {
                    setHover(true);
                    setType('change');
                  }}
                  as="li"
                  bsPrefix=" "
                >
                  <Link to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="feather icon-log-out" /> Logout
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </React.Fragment>
  );
};

export default NavRight;
