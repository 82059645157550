const permissions = JSON.parse(localStorage.getItem('USER_DETAILS'));
// console.log('per', permissions);
const menuItems = {
  items: [
    {
      id: 'navigation',
      title: 'Navigation',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: 'dashboard',
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-home',
          url: '/app/dashboard/analytics'
        }
      ]
    },
    {
      id: 'admin-panel',
      title: 'Admin Panel',
      type: 'group',
      icon: 'icon-monitor',
      children: [
        {
          id: 'admin',
          title: 'Admin',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ADMIN') ? '' : 'nav-item d-none',
          icon: 'feather icon-user',
          url: '/admin'
        },
        {
          id: 'role',
          title: 'Role',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
          icon: 'feather icon-check-square',
          url: '/role'
        },
        {
          id: 'user',
          title: 'User',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-USER') ? '' : 'nav-item d-none',
          icon: 'feather icon-users',
          url: '/user'
        },
        {
          id: 'investments',
          title: 'Investments',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-INVESTMENT') ? '' : 'nav-item d-none',
          icon: 'feather icon-activity',
          url: '/investment'
        },
        {
          id: 'projects',
          title: 'Project',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-PROJECT') ? '' : 'nav-item d-none',
          icon: 'feather icon-briefcase',
          url: '/projects'
        },
        {
          id: 'investors',
          title: 'Investors',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
          icon: 'feather icon-user-plus',
          url: '/investors'
        },
        {
          id: 'manual-payouts',
          title: 'Manual Payments',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
          icon: 'feather icon-loader',
          url: '/payments'
        },
        {
          id: 'referal',
          title: 'Referal',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-REFER') ? '' : 'nav-item d-none',
          icon: 'feather icon-droplet',
          url: '/referal'
        },
        {
          id: 'tickects',
          title: 'Tickets',
          type: 'collapse',
          classes: permissions?.includes('CAN-LIST-TICKET') ? '' : 'nav-item d-none',
          icon: 'feather icon-package',

          children: [
            {
              id: 'Support',
              title: 'All Tickets',
              type: 'item',
              classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
              url: '/ticket'
            },
            {
              id: 'departments',
              title: 'Departments',
              type: 'item',
              classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
              url: '/department'
            }
          ]
        },

        {
          id: 'banner',
          title: 'Banner',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-BANNER') ? '' : 'nav-item d-none',
          icon: 'feather icon-monitor',
          url: '/banner'
        },
        {
          id: 'reports',
          title: 'Reports',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-REPORT') ? '' : 'nav-item d-none',
          icon: 'feather icon-search',
          url: '/reports'
        },
        {
          id: 'Raise_Capital',
          title: 'Raise Capital',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-RAISECAPITAL') ? '' : 'nav-item d-none',
          icon: 'feather icon-move',
          url: '/capital'
        },

        {
          id: 'faq',
          title: 'Faq',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-FAQ') ? '' : 'nav-item d-none',
          icon: 'feather icon-help-circle',
          url: '/faq'
        },
        {
          id: 'notification',
          title: 'Notification',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
          icon: 'feather icon-bell',
          url: '/notification'
        },
        {
          id: 'blogs',
          title: 'Blogs',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-BLOG') ? '' : 'nav-item d-none',
          icon: 'feather icon-bookmark',
          url: '/blogs'
        },
        {
          id: 'testimonals',
          title: 'Testimonals',
          type: 'item',
          classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
          icon: 'feather icon-film',
          url: '/testimonals'
        }
        // {
        //   id: 'partners',
        //   title: 'Partners',
        //   type: 'item',
        //   // classes: permissions?.includes('CAN-LIST-ROLES') ? '' : 'nav-item d-none',
        //   icon: 'feather icon-thumbs-up',
        //   url: '/partners'
        // }
      ]
    }
    // {
    //   id: 'support',
    //   title: 'Support',
    //   type: 'group',
    //   icon: 'icon-support',
    //   children: [
    //     {
    //       id: 'menu-level',
    //       title: 'Menu Levels',
    //       type: 'collapse',
    //       icon: 'feather icon-menu',
    //       children: [
    //         {
    //           id: 'menu-level-1.1',
    //           title: 'Menu Level 1.1',
    //           type: 'item',
    //           url: '#!'
    //         },
    //         {
    //           id: 'menu-level-1.2',
    //           title: 'Menu Level 2.2',
    //           type: 'collapse',
    //           children: [
    //             {
    //               id: 'menu-level-2.1',
    //               title: 'Menu Level 2.1',
    //               type: 'item',
    //               url: '#'
    //             },
    //             {
    //               id: 'menu-level-2.2',
    //               title: 'Menu Level 2.2',
    //               type: 'collapse',
    //               children: [
    //                 {
    //                   id: 'menu-level-3.1',
    //                   title: 'Menu Level 3.1',
    //                   type: 'item',
    //                   url: '#'
    //                 },
    //                 {
    //                   id: 'menu-level-3.2',
    //                   title: 'Menu Level 3.2',
    //                   type: 'item',
    //                   url: '#'
    //                 }
    //               ]
    //             }
    //           ]
    //         }
    //       ]
    //     },
    //     {
    //       id: 'disabled-menu',
    //       title: 'Disabled Menu',
    //       type: 'item',
    //       url: '#',
    //       classes: 'nav-item disabled',
    //       icon: 'feather icon-power'
    //     },
    //     {
    //       id: 'sample-page',
    //       title: 'Sample Page',
    //       type: 'item',
    //       url: '/sample-page',
    //       classes: 'nav-item',
    //       icon: 'feather icon-sidebar'
    //     }
    //   ]
    // }
  ]
};

export default menuItems;
