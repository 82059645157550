import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Loader from './components/Loader/Loader';
import AdminLayout from './layouts/AdminLayout';

import GuestGuard from './components/Auth/GuestGuard';
import AuthGuard from './components/Auth/AuthGuard';

import { BASE_URL } from './config/constant';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<Loader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: '/signin',
    component: lazy(() => import('./views/auth/signin/SignIn1'))
  },

  {
    exact: true,
    path: '/maintenance/offline-ui',
    component: lazy(() => import('./views/maintenance/OfflineUI'))
  },

  {
    exact: true,
    path: '/auth/reset-password-1',
    component: lazy(() => import('./views/auth/reset-password/ResetPassword1'))
  },

  {
    exact: true,
    path: '/change-password',
    component: lazy(() => import('./views/auth/ChangePassword'))
  },

  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: '/',
        component: lazy(() => import('./views/dashboard/DashAnalytics'))
      },
      {
        exact: true,
        path: '/admin',
        component: lazy(() => import('./views/panels/Admin/Admin'))
      },
      {
        exact: true,
        path: '/admin/add-admin',
        component: lazy(() => import('./views/panels/Admin/AddAdmin'))
      },
      {
        exact: true,
        path: '/role',
        component: lazy(() => import('./views/panels/Roles/Roles'))
      },

      {
        exact: true,
        path: '/role/add-role',
        component: lazy(() => import('./views/panels/Roles/AddRole'))
      },
      {
        exact: true,
        path: '/user',
        component: lazy(() => import('./views/panels/User/User'))
      },
      {
        exact: true,
        path: '/user/add-user',
        component: lazy(() => import('./views/panels/User/AddUser'))
      },
      {
        exact: true,
        path: '/user/user-profile',
        component: lazy(() => import('./views/panels/User/UserProfile'))
      },
      {
        exact: true,
        path: '/user/user-kyc',
        component: lazy(() => import('./views/panels/User/Banking'))
      },
      {
        exact: true,
        path: '/investment',
        component: lazy(() => import('./views/panels/Investment/Investment'))
      },
      {
        exact: true,
        path: '/investment/add-investment',
        component: lazy(() => import('./views/panels/Investment/AddInvestment'))
      },
      {
        exact: true,
        path: '/investment',
        component: lazy(() => import('./views/panels/Investment/Investment'))
      },
      {
        exact: true,
        path: '/blogs',
        component: lazy(() => import('./views/panels/Blogs/blogs'))
      },
      {
        exact: true,
        path: '/blogs/add-blogs',
        component: lazy(() => import('./views/panels/Blogs/AddBlogs'))
      },
      {
        exact: true,
        path: '/projects',
        component: lazy(() => import('./views/panels/Projects/project'))
      },

      {
        exact: true,
        path: '/projects/investors',
        component: lazy(() => import('./views/panels/Projects/Investors'))
      },
      {
        exact: true,
        path: '/projects/add-projects',
        component: lazy(() => import('./views/panels/Projects/Addproject'))
      },
      {
        exact: true,
        path: '/investors',
        component: lazy(() => import('./views/panels/Investors/investors'))
      },
      {
        exact: true,
        path: '/referal',
        component: lazy(() => import('./views/panels/Referal/referal'))
      },
      {
        exact: true,
        path: '/referal/view-referal',
        component: lazy(() => import('./views/panels/Referal/viewReferal'))
      },
      {
        exact: true,
        path: '/reports',
        component: lazy(() => import('./views/panels/Reports/Reports'))
      },
      {
        exact: true,
        path: '/banner',
        component: lazy(() => import('./views/panels/Banner/Banner'))
      },
      {
        exact: true,
        path: '/testimonals',
        component: lazy(() => import('./views/panels/Testimonals/testimonals'))
      },
      {
        exact: true,
        path: '/partners',
        component: lazy(() => import('./views/panels/Partners/Partner'))
      },
      {
        exact: true,
        path: '/testimonals/add-testimonals',
        component: lazy(() => import('./views/panels/Testimonals/AddTestimonals'))
      },
      {
        exact: true,
        path: '/ticket',
        component: lazy(() => import('./views/panels/support/support'))
      },
      {
        exact: true,
        path: '/ticket/add-ticket',
        component: lazy(() => import('./views/panels/support/AddSupport'))
      },
      {
        exact: true,
        path: '/ticket/view-ticket',
        component: lazy(() => import('./views/panels/support/ReplySupport'))
      },
      {
        exact: true,
        path: '/department',
        component: lazy(() => import('./views/panels/support/department'))
      },
      {
        exact: true,
        path: '/capital',
        component: lazy(() => import('./views/panels/Raise-Capital/raiseCapital'))
      },
      {
        exact: true,
        path: '/capital/view-capital',
        component: lazy(() => import('./views/panels/Raise-Capital/viewCapital'))
      },
      {
        exact: true,
        path: '/payments',
        component: lazy(() => import('./views/panels/Payments/payments'))
      },

      {
        exact: true,
        path: '/faq',
        component: lazy(() => import('./views/panels/Faq/Faq'))
      },
      {
        exact: true,
        path: '/faq/add-faq',
        component: lazy(() => import('./views/panels/Faq/AddFaq'))
      },
      {
        exact: true,
        path: '/notification',
        component: lazy(() => import('./views/panels/Notification/notification'))
      },
      {
        exact: true,
        path: '/push-notification',
        component: lazy(() => import('./views/panels/Notification/pushNotification'))
      },

      {
        path: '*',
        exact: true,
        component: () => <Redirect to={BASE_URL} />
      }
    ]
  }
];

export default routes;
