import httpClient from '../Constant/httpClient';
import { URL } from '../Constant/URL';

const notification = (data) => {
  return httpClient.post(URL.NOTIFICATION, data);
};

// PUSH NOTIFICATION

const notificationList = () => {
  return httpClient.get(URL.NOTIFICATION_LIST);
};

const notificationCurrentList = () => {
  return httpClient.get(URL.NOTIFICATION_CURRENT);
};

const notificationStatus = () => {
  return httpClient.get(URL.NOTIFICATION_STATUS);
};

const notificationAdminView = () => {
  return httpClient.get(URL.NOTIFICATION_ADMIN_VIEW);
};

const notificationServices = {
  notification,
  notificationList,
  notificationCurrentList,
  notificationStatus,
  notificationAdminView
};

export default notificationServices;
